import { useEffect, useState } from 'react';

import { useDebounce } from 'common/hooks/useDebounce';
import { areThresholdsEqual } from './helpers';

export const useThresholdDebounce = (
  threshold: number | number[],
  changeThreshold: (threshold: number | number[]) => void,
) => {
  const [localThreshold, setLocalThreshold] = useState(threshold);
  const debouncedThreshold = useDebounce(localThreshold);

  useEffect(() => {
    changeThreshold(debouncedThreshold);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedThreshold]);

  useEffect(() => {
    if (!areThresholdsEqual(threshold, localThreshold)) {
      setLocalThreshold(threshold);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [threshold]);

  return { localThreshold, setLocalThreshold };
};
