import { DataLayer } from 'components/map';
import { titilerApiAdapter } from 'services/titiler/titilerApiAdapter';
import { useGetExtentPolygon } from 'views/areas/areaDetails/useGetExtentPolygon';

interface VegetationPuneDataLayerProps {
  isSpecialModeActive: boolean;
  url: string;
}

export const VegetationPuneDataLayer = ({ isSpecialModeActive, url }: VegetationPuneDataLayerProps) => {
  const cogUrl = titilerApiAdapter.buildNamedColorScaleFloatCogUrl(url, {
    range: [-1, 1],
    colormapName: 'rdylgn',
    noDataValue: '0',
  });

  const { extentLatLngBounds, isInfoLoading } = useGetExtentPolygon(url);

  if (isInfoLoading || !extentLatLngBounds) {
    return null;
  }

  return (
    <DataLayer
      isVisible={isSpecialModeActive}
      layerName={'vegetationLayer-100'}
      zIndex={490}
      cogUrl={cogUrl}
      bounds={extentLatLngBounds}
    />
  );
};
