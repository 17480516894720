import { useLocation } from 'react-router-dom';

import { LIGHT_GRAYSCALE_SATELLITE_URL_MODIFIER } from 'common/constants';
import { getDarkGrayscaleSatelliteUrlModifier, getSatelliteUrlModifier } from 'common/utils/map';
import { useLayerManagerStore } from 'components/layerManager/layerManagerStore';
import { useTimelineService } from 'components/Timeline/timelineService';
import { WithLayerIndex } from 'components/layerManager/draggableLayerManager/WithLayerIndex';
import { LAYERS_KEYS } from 'components/layerManager/draggableLayerManager/draggableLayersKeys';
import { useGetAreReportsActiveUseCase } from 'views/reports/getAreReportsActiveUseCase';
import { areasRepository } from 'infrastructure/areas/areasRepository';
import { GreeneryActiveTab } from 'domain/greenery/enums';
import { MINERALIZATION_PATH_NAME } from 'domain/exploration/constants';
import { MONITORING_PATH_NAME } from 'domain/monitoring/constants';
import { PROSPECTING_PATH_NAME } from 'domain/prospecting/constants';
import { SatelliteImageLayer } from './SatelliteImageLayer';

interface SatelliteImageProps {
  areaId: number;
  isGrayscaleSatelliteActive: boolean;
}

export const SatelliteImage = ({ areaId, isGrayscaleSatelliteActive }: SatelliteImageProps) => {
  const { timestamp } = useTimelineService();
  const { pathname } = useLocation();
  const isVhrView = pathname.includes(GreeneryActiveTab.VHR) && areaId === 236;

  const { areaOfInterestDetails } = areasRepository.useFetchAreaOfInterestDetails(areaId);
  const areaName = areaOfInterestDetails?.id;

  const isMonitoringView = pathname.includes(MONITORING_PATH_NAME);
  const isProspectingView = pathname.includes(PROSPECTING_PATH_NAME);

  // TODO - till view will be implemented on backend
  const isMineralsView = pathname.includes(MINERALIZATION_PATH_NAME);

  const currentTimestamp = isMonitoringView || isProspectingView || isMineralsView ? undefined : timestamp || undefined;
  // TODO

  const isSatelliteVisible = useLayerManagerStore.use.isSatelliteVisible();
  const satelliteBrightness = useLayerManagerStore.use.grayscaleSatelliteBrightness();
  const areReportsActive = useGetAreReportsActiveUseCase();

  const { aoiSatelliteUrl } = areasRepository.useFetchCurrentSatelliteImageUrl(areaId, currentTimestamp);

  const colorMap = areReportsActive
    ? LIGHT_GRAYSCALE_SATELLITE_URL_MODIFIER
    : getDarkGrayscaleSatelliteUrlModifier(satelliteBrightness);

  const colorFormula = getSatelliteUrlModifier(satelliteBrightness);

  return aoiSatelliteUrl && areaName ? (
    <WithLayerIndex
      Component={SatelliteImageLayer}
      props={{
        aoiSatelliteUrl,
        isGrayscaleSatelliteActive,
        isVisible: isSatelliteVisible,
        layerName: LAYERS_KEYS.SATELLITE,
        isVhrView,
        colorMap,
        colorFormula,
      }}
      sectionId={LAYERS_KEYS.SATELLITE}
    />
  ) : null;
};
