import { Loading } from 'ui/molecules/loading';
import { DataLayer } from 'components/map';
import { titilerApiAdapter } from 'services/titiler/titilerApiAdapter';
import { WithLayerIndex } from 'components/layerManager/draggableLayerManager/WithLayerIndex';
import { useGetExtentPolygon } from 'views/areas/areaDetails/useGetExtentPolygon';
import { EXPLORATION_LAYERS_KEYS } from '../explorationDraggableLayersKeys';

interface Props {
  url: string;
  rescaleFactors: [number, number][];
  visible: boolean;
  opacity: number;
}

export const CompositesMapLayer = ({ url, rescaleFactors, visible, opacity }: Props) => {
  const titilerUrl = titilerApiAdapter.buildGenericRGBImageUrl(`${url}`, {
    rescaleFactors,
    fileType: 'png',
  });

  const { extentLatLngBounds, isInfoLoading } = useGetExtentPolygon(url);

  if (isInfoLoading) {
    return <Loading />;
  }

  if (!titilerUrl || !extentLatLngBounds) {
    return null;
  }

  return (
    <WithLayerIndex
      Component={DataLayer}
      props={{
        isVisible: visible,
        layerName: 'compositesLayer',
        cogUrl: titilerUrl,
        bounds: extentLatLngBounds,
        opacity,
      }}
      sectionId={EXPLORATION_LAYERS_KEYS.COMPOSITES}
    />
  );
};
