import { DataLayer } from 'components/map';
import { useGetCogWithBoundsImproved } from 'services/titiler/hooks';
import { titilerApiAdapter } from 'services/titiler/titilerApiAdapter';
import { Loading } from 'ui/molecules/loading';

interface ClustersMapLayerProps {
  url: string;
  amount: number;
  opacity: number;
  isVisible: boolean;
  zIndex: number;
}

export const ClustersMapLayer = ({ url, amount, opacity, isVisible, zIndex }: ClustersMapLayerProps) => {
  const { cogUrl, extentLatLngBounds, isInfoLoading } = useGetCogWithBoundsImproved(
    titilerApiAdapter.buildCogUrlWithColormapName,
    url,
    {
      range: [0, amount - 1],
      colormapName: 'cubehelix',
    },
  );

  if (isInfoLoading) {
    return <Loading />;
  }

  if (cogUrl && extentLatLngBounds) {
    return (
      <DataLayer
        isVisible={isVisible}
        layerName="clusters-layer"
        zIndex={zIndex}
        cogUrl={cogUrl}
        bounds={extentLatLngBounds}
        opacity={opacity}
      />
    );
  }

  return null;
};
