// TODO: remove when mercedes data will be handled
import { useEffect, useState } from 'react';

import { useRefreshLayer } from 'common/hooks/useRefreshLayer';
import { useQueryParameter } from 'common/navigation/hooks';
import { useLayerManagerStore } from 'components/layerManager/layerManagerStore';
import { WATERBODY_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { planetscopeWaterbodies, pleiadesWaterbodies } from 'views/water/puneData';
import { WaterAreaSpecialMapLayer } from 'views/water/surface/WaterAreaSpecialMapLayer';
import { useTimelineService } from 'components/Timeline/timelineService';

export const WaterAreaSpecialMapContent = () => {
  const waterbodyIdQuery = useQueryParameter(WATERBODY_QUERY_PARAMETER);

  const layersVisibility = useLayerManagerStore.use.layersVisibility();

  interface PuneWaterbody {
    id: number;
    waterbodyId: number;
    waterbody: string;
    date: string;
    url: string;
  }

  const [waterbodies, setWaterbodies] = useState<PuneWaterbody[]>([]);

  const { timestamp } = useTimelineService();

  useEffect(() => {
    if (timestamp) {
      if (layersVisibility['pleiades']) {
        setWaterbodies(pleiadesWaterbodies.filter((url) => url.date === timestamp) || []);
      }

      if (layersVisibility['planet_scope']) {
        setWaterbodies(planetscopeWaterbodies.filter((url) => url.date === timestamp) || []);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timestamp]);

  const filteredWaterbodies = waterbodyIdQuery
    ? waterbodies.filter((waterbody) => waterbody.waterbodyId === Number(waterbodyIdQuery))
    : waterbodies;

  const cogUrls = filteredWaterbodies.map((waterbody) => waterbody.url);

  const isSpecialModeActive = layersVisibility['pleiades'] === true || layersVisibility['planet_scope'] === true;

  const { visible } = useRefreshLayer([
    cogUrls,
    waterbodyIdQuery,
    layersVisibility['pleiades'],
    layersVisibility['planet_scope'],
  ]);

  return (
    <>
      {isSpecialModeActive &&
        visible &&
        cogUrls.map((url) => url && <WaterAreaSpecialMapLayer key={url} url={url} pane={'dataLayer'} />)}
    </>
  );
};

/* TODO */
