import chroma from 'chroma-js';

import { type ColormapArray, type ColormapDictionary, type Limits } from 'common/types';

export const RGBToHSL = (color: string): string => {
  const parsedColor = color.replace('rgb(', '').replace(')', '');
  const [rr, gg, bb] = parsedColor.split(',').map(Number);
  const r = rr / 255;
  const g = gg / 255;
  const b = bb / 255;

  const cmin = Math.min(r, g, b);
  const cmax = Math.max(r, g, b);
  const delta = cmax - cmin;
  let h = 0;
  let s = 0;
  let l = 0;

  if (delta == 0) h = 0;
  else if (cmax == r) h = ((g - b) / delta) % 6;
  else if (cmax == g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0) h += 360;

  l = (cmax + cmin) / 2;

  s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));

  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return 'hsl(' + h + ',' + s + '%,' + l + '%)';
};

export const parsedHSLToHex = (h: number, s: number, l: number): string => {
  s /= 100;
  l /= 100;

  const c = (1 - Math.abs(2 * l - 1)) * s,
    x = c * (1 - Math.abs(((h / 60) % 2) - 1)),
    m = l - c / 2;
  let r = 0,
    g = 0,
    b = 0;

  if (0 <= h && h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (60 <= h && h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (120 <= h && h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (180 <= h && h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (240 <= h && h < 300) {
    r = x;
    g = 0;
    b = c;
  } else if (300 <= h && h < 360) {
    r = c;
    g = 0;
    b = x;
  }

  let rr = Math.round((r + m) * 255).toString(16);
  let gg = Math.round((g + m) * 255).toString(16);
  let bb = Math.round((b + m) * 255).toString(16);

  if (rr.length == 1) rr = '0' + r;
  if (gg.length == 1) gg = '0' + g;
  if (bb.length == 1) bb = '0' + b;

  return '#' + rr + gg + bb;
};

export const pixelValueToHexHelper = (value: number, limits: Limits, scale: chroma.Scale<chroma.Color>): string => {
  return scale((value - limits.min) / (limits.max - limits.min)).hex();
};

export const sanitizeRgbColor = (color: string): string => color.replace('rgb(', '').replace(')', '');

export const getColorScaleValuesHelper = (min: number, max: number, areBetweenValues = true) => {
  const center = (max + min) / 2;

  const colorScaleValues = areBetweenValues
    ? [min, (center + min) / 2, center, (center + max) / 2, max]
    : [min, center, max];

  return colorScaleValues;
};

export const setColorOpacity = (color: string, opacity: number): string => chroma(color).alpha(opacity).css();

export const normalizeRange = (value: number, min: number, max: number) => {
  return (value - min) / (max - min);
};

export const normalizeColormapObject = (colormapObject: ColormapDictionary) =>
  Object.entries(colormapObject).reduce(
    (acc, [key, value]) => ({ ...acc, [Math.floor(Number(key) * 255)]: value }),
    {},
  );

const adjustOpacityRange = (value: number, opacityMin: number, opacityMax: number): number => {
  const normalizedValue = normalizeRange(value, 0, 255);

  return Math.round(normalizedValue * (opacityMax - opacityMin) + opacityMin);
};

export const buildDiscreteOpacityMonoColormapArray = (
  color: string,
  opacityMin: number,
  opacityMax: number,
): ColormapArray => {
  const sanitizedColor = sanitizeRgbColor(color);
  const rangesNo = 5;

  const colormap = [];

  for (let i = 0; i < rangesNo; i++) {
    const alpha = 32 * Math.pow(2, i - 1) - 1;
    const adjustedAlpha = adjustOpacityRange(alpha, opacityMin, opacityMax);
    const limitedAlpha = i === 0 ? 0 : adjustedAlpha;

    const adjust = i === rangesNo - 1 ? 0.001 : 0;

    colormap.push([
      [(i * 1) / rangesNo, ((i + 1) * 1) / rangesNo + adjust],
      [...sanitizedColor.split(',').map(Number), limitedAlpha],
    ]);
  }

  return colormap;
};

export const buildConstantMonoColormapArray = (color: string, classifierId: number | null): ColormapArray => {
  const cutoffValue = classifierId === 2 ? 0.2 : 0.001;
  const endValue = classifierId === 4 ? 255 : 1;

  const sanitizedColor = sanitizeRgbColor(color);
  const rgbaArray = [...sanitizedColor.split(',').map(Number), 255];
  const colormapArray = [[[cutoffValue, endValue], rgbaArray]];

  return colormapArray;
};

export const buildGradientColormapArray = (
  colormap: ColormapDictionary,
  min: number,
  max: number,
  steps = 50,
): ColormapArray => {
  const colormapArray = [];
  const increment = (max - min) / steps;

  const sortedColors = Object.entries(colormap)
    .sort((a, b) => Number(a[0]) - Number(b[0]))
    .map((entry) => entry[1]);

  const gradientScale = chroma.scale(sortedColors).domain([min, max]);

  for (let i = 0; i < steps; i++) {
    const value = min + i * increment;

    const minValueAdjust = i === 0 ? 0.0001 : 0;
    const maxValueAdjust = i === steps - 1 ? 0.0001 : 0;

    colormapArray.push([
      [Number((value + minValueAdjust).toFixed(4)), Number((value + increment + maxValueAdjust).toFixed(4))],
      [...gradientScale(value).rgb(), 255],
    ]);
  }

  return colormapArray;
};

export const buildGradientRangeColormapArray = (
  colormap: ColormapDictionary,
  realMin: number,
  realMax: number,
  rangeMin: number,
  rangeMax: number,
  steps = 50,
): ColormapArray => {
  const colormapArray = [];
  const increment = (rangeMax - rangeMin) / steps;

  const sortedColors = Object.entries(colormap)
    .sort((a, b) => Number(a[0]) - Number(b[0]))
    .map((entry) => entry[1]);

  const gradientScale = chroma.scale(sortedColors).domain([rangeMin, rangeMax]);

  if (realMin < rangeMin) {
    colormapArray.push([
      [realMin, rangeMin],
      [...gradientScale(rangeMin).rgb(), 255],
    ]);
  }

  for (let i = 0; i < steps; i++) {
    const value = rangeMin + i * increment;

    colormapArray.push([
      [Number(value.toFixed(4)), Number((value + increment).toFixed(4))],
      [...gradientScale(value).rgb(), 255],
    ]);
  }

  if (realMax > rangeMax) {
    colormapArray.push([
      [rangeMax, realMax],
      [...gradientScale(rangeMax).rgb(), 255],
    ]);
  }

  return colormapArray;
};

export const buildDiscreteMonoColormapArray = (
  color: string,
  steps: number,
  min: number,
  max: number,
): ColormapArray => {
  const colormap = [];
  const increment = (max - min) / steps;
  const sanitizedColor = sanitizeRgbColor(color);

  for (let i = 0; i < steps; i++) {
    const firstValue = i * increment + min;
    const maxValueAdjust = i === steps - 1 ? 0.001 : 0;

    colormap.push([
      [Number(firstValue.toFixed(4)), Number((firstValue + increment + maxValueAdjust).toFixed(4))],
      [...sanitizedColor.split(',').map(Number), (255 / steps) * (i + 1)],
    ]);
  }

  return colormap;
};

export const buildDiscreteColorArray = (colormapObject: ColormapDictionary, range: number[]): ColormapArray => {
  const colors = Object.entries(colormapObject)
    .sort((a, b) => Number(a[0]) - Number(b[0]))
    .map((entry) => entry[1]);
  const increment = Number(((range[1] - range[0]) / colors.length).toFixed(3));
  const segmentedColorArray: ColormapArray = [];

  colors.forEach((color: string, index: number) => {
    const segmentStart = range[0] + index * increment;
    const segmentEnd = index === colors.length - 1 ? range[1] + 0.001 : segmentStart + increment;
    const adjustedSegmentStart = index === 0 ? segmentStart + 0.001 : segmentStart;

    const rgbaArray = chroma(color).rgba();
    rgbaArray[rgbaArray.length - 1] = rgbaArray[rgbaArray.length - 1] * 255;

    segmentedColorArray.push([[Number(adjustedSegmentStart.toFixed(3)), Number(segmentEnd.toFixed(3))], rgbaArray]);
  });

  return segmentedColorArray;
};

export const buildDiscreteOpacityMonoColormap = (color: string, segmentsQty: number): ColormapDictionary => {
  const opacityIncrement = 1 / segmentsQty;
  const colormap: ColormapDictionary = {};

  for (let i = 0; i < segmentsQty; i++) {
    const opacity = (i + 1) * opacityIncrement;
    colormap[i * (1 / (segmentsQty - 1))] = setColorOpacity(color, opacity);
  }

  return colormap;
};

export const addAlphaToColormapObject = (colormapObject: ColormapDictionary, alpha: number): ColormapDictionary => {
  const colormap: ColormapDictionary = {};

  for (const key in colormapObject) {
    colormap[key] = String(chroma(colormapObject[key]).alpha(alpha));
  }

  return colormap;
};
