/* // TODO: remove when mercedes data will be handled */
import { TileLayer } from 'react-leaflet';

import { titilerInfoService } from 'services/titiler/titilerInfoService';
import { titilerApiAdapter } from 'services/titiler/titilerApiAdapter';
import { getAzureBlobStorageUrl, getBoundsFromCogInfo } from 'services/titiler/utils';

interface WaterAreaPuneMapLayerProps {
  url: string;
  pane: string;
}

export const WaterAreaSpecialMapLayer = ({ url, pane }: WaterAreaPuneMapLayerProps) => {
  const cogUrl = titilerApiAdapter.buildNamedColorScaleFloatCogUrl(url, {
    range: [0, 1],
    colormapName: 'blues_r',
    noDataValue: '0',
  });

  const { info: lakeInfo } = titilerInfoService.useGetInfoGeneric(getAzureBlobStorageUrl(url));
  const cogBounds = getBoundsFromCogInfo(lakeInfo);

  return <>{cogBounds && <TileLayer pane={pane} url={cogUrl} bounds={cogBounds} />}</>;
};
/* TODO */
