import { useEffect, useState } from 'react';

import { buildSliderMarks, isValueInsideRange, roundNumber } from 'common/utils';
import { type LayerRange, type LayerManagerSliderConfig } from 'common/types';
import { Container, Slider } from 'ui';
import { useLayerManagerStore } from './layerManagerStore';
import { LayerManagerThresholdInput } from './ThresholdInput';
import { useThresholdDebounce } from './useThresholdDebounce';

interface ThresholdSliderProps {
  layerRange: LayerRange;
  layerName: string;
  config: LayerManagerSliderConfig;
}

export const LayerManagerThresholdSlider = ({ layerRange, layerName, config }: ThresholdSliderProps) => {
  const layersTresholds = useLayerManagerStore.use.layersTresholdsValues();
  const changeLayerTreshold = useLayerManagerStore.use.updateLayersTresholdsValues();
  const changeThreshold = (threshold: number | number[]) => {
    changeLayerTreshold({
      [layerName]: threshold,
    });
  };

  const [showInput, setShowInput] = useState(false);

  const { step, precision, isDoubleHandle, variant, restricted, withInput } = config;
  const { min, max, initialValue } = layerRange;

  const marks = buildSliderMarks(min, max, 1, precision);
  const threshold = layersTresholds[layerName];
  const defaultValue = isDoubleHandle ? [min, max] : min;

  const isThresholdValid = isValueInsideRange(
    threshold,
    isDoubleHandle,
    min,
    max,
    step,
    restricted.min,
    restricted.max,
  );
  const validThreshold = isThresholdValid ? threshold : defaultValue;

  const { localThreshold: value, setLocalThreshold: setValue } = useThresholdDebounce(validThreshold, changeThreshold);

  const onChange = (threshold: number | number[]) => {
    if (Array.isArray(threshold) && threshold.length === 2 && threshold[0] === threshold[1]) {
      return;
    }

    if (isValueInsideRange(threshold, isDoubleHandle, min, max, step, restricted.min, restricted.max)) {
      withInput && !showInput && setShowInput(true);

      const newThreshold =
        typeof threshold === 'number'
          ? roundNumber(threshold, precision)
          : [roundNumber(threshold[0], precision), roundNumber(threshold[1], precision)];

      setValue(newThreshold);
    }
  };

  useEffect(() => {
    if (!isThresholdValid) {
      const newThreshold = isDoubleHandle ? [initialValue, max] : initialValue;
      onChange(newThreshold);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container ph={4} pt={10} wMax variant="VERTICAL">
      <Slider
        ariaLabel="Treshold adjustment"
        {...{ variant, marks, value, onChange, defaultValue, min, max, step }}
        isThumbLabel
      />
      {withInput && <LayerManagerThresholdInput {...{ value, min, max, step, onChange, showInput, setShowInput }} />}
    </Container>
  );
};
