import { LatLngBounds } from 'leaflet';

import { isValidBounds } from 'common/typeguards';
import { RESEARCH_ML_STORAGE_URL } from 'common/constants/services';
import { roundNumber } from 'common/utils';
import { type LayerRange } from 'common/types';
import { type CogBandStats, type TitilerInfo } from 'services/titiler/types';

export const sanitizeMlStorageUrl = (url: string) => url.replace(RESEARCH_ML_STORAGE_URL, '').replace(/\?se=~*.*/g, '');

export const getAzureBlobStorageUrl = (url: string) => `/vsiaz${sanitizeMlStorageUrl(url)}`;

export const getBoundsFromCogInfo = (info: TitilerInfo) => {
  const bounds = info?.bounds;

  return isValidBounds(bounds)
    ? new LatLngBounds([
        [bounds[3], bounds[2]],
        [bounds[1], bounds[0]],
      ])
    : undefined;
};

export const convertCogBandStatsToRange = (stats: CogBandStats, precision: number): LayerRange => {
  return {
    min: roundNumber(stats.min, precision),
    max: roundNumber(stats.max, precision),
    initialValue: roundNumber(stats.percentile_98, precision),
  };
};
