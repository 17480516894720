import { useQueryParameter } from 'common/navigation/hooks';
import { COMPARISON_OPTION_NAME } from 'common/navigation/constants';
import { useLayerManagerStore } from 'components/layerManager/layerManagerStore';
import { VIEW_MODE_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { areasRepository } from 'infrastructure/areas/areasRepository';
import { WithLayerIndex } from 'components/layerManager/draggableLayerManager/WithLayerIndex';
import { LAYERS_KEYS } from 'components/layerManager/draggableLayerManager/draggableLayersKeys';
import {
  INFRASTRUCTURE_LAYER_MAIN_COLOR,
  INFRASTRUCTURE_LAYER_SECONDARY_COLOR,
  INFRASTRUCTURE_LAYER_REPORT_COLOR,
} from 'domain/areas/constants';
import { Shape } from './Shape';

interface ShapesCollectionProps {
  areaName: string;
  areReportsActive?: boolean;
}

export const ShapesCollection = ({ areaName, areReportsActive = false }: ShapesCollectionProps) => {
  const viewQuery = useQueryParameter(VIEW_MODE_QUERY_PARAMETER);
  const isComparisonActive: boolean = viewQuery === COMPARISON_OPTION_NAME;

  const geoJSONsVisibility = useLayerManagerStore.use.geoJSONsVisibility();

  const { shapes } = areasRepository.useFetchInfrastructureShapes(areaName);

  const infrastructureLayerColor = areReportsActive
    ? INFRASTRUCTURE_LAYER_REPORT_COLOR
    : isComparisonActive
    ? INFRASTRUCTURE_LAYER_SECONDARY_COLOR
    : INFRASTRUCTURE_LAYER_MAIN_COLOR;

  return (
    <>
      {shapes.map(({ id, name, shape_url }) => (
        <WithLayerIndex
          key={id}
          Component={Shape}
          props={{ id, url: shape_url, name, color: infrastructureLayerColor, isVisible: geoJSONsVisibility[name] }}
          sectionId={LAYERS_KEYS.INFRASTRUCTURE}
        />
      ))}
    </>
  );
};
