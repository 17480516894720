import { Fragment } from 'react/jsx-runtime';

import { buildCoordinatesOnlyTooltipStringHelper } from 'common/utils/map';
import { MapClick } from 'components/map/tooltip/MapClick';
import { type MoistureIndicator, type MoistureResult } from 'domain/water/types';
import { MoistureMapMultiDataLayer } from './MoistureMapMultiDataLayer';

export const MoistureMapMultiDataLayers = ({
  results,
  indicator,
}: {
  results: MoistureResult[];
  indicator: MoistureIndicator;
}) =>
  results.map((result) => {
    return (
      <Fragment key={result.id}>
        <MoistureMapMultiDataLayer key={result.id} result={result} indicator={indicator} />
        <MapClick tooltipStringBuilder={buildCoordinatesOnlyTooltipStringHelper} />
      </Fragment>
    );
  });
