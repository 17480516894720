import { LabeledCheckbox } from 'ui';
import { useLayerManagerStore } from '../layerManagerStore';
import { IndentedContentWraper } from '../styled';
import { DraggableLayerManagerSection } from '../draggableLayerManager/DraggableLayerManagerSection';
import { useInitializeLayersOrderRecord } from '../draggableLayerManager/useInitializeLayersOrderRecord';
import { type LAYERS_KEYS } from '../draggableLayerManager/draggableLayersKeys';

interface ExtentItemProps {
  id: LAYERS_KEYS;
}

export const ExtentItem = ({ id }: ExtentItemProps) => {
  const isExtentVisible = useLayerManagerStore.use.isExtentVisible();
  const toggleExtentVisibility = useLayerManagerStore.use.toggleExtentVisibility();

  useInitializeLayersOrderRecord(id);

  return (
    <DraggableLayerManagerSection {...{ id }}>
      <IndentedContentWraper>
        <LabeledCheckbox variant="WIDE" onChange={toggleExtentVisibility} checked={isExtentVisible || false}>
          Extent
        </LabeledCheckbox>
      </IndentedContentWraper>
    </DraggableLayerManagerSection>
  );
};
