import { useEffect, useState } from 'react';

import { type LeafletBounds } from 'common/types/mapData';
import { titilerApiAdapter } from 'services/titiler/titilerApiAdapter';
import { getAzureBlobStorageUrl } from 'services/titiler/utils';
import { useGetExtentPolygon } from 'views/areas/areaDetails/useGetExtentPolygon';
import * as map from 'components/map';

interface SatelliteImageLayerProps {
  aoiSatelliteUrl: string;
  isGrayscaleSatelliteActive: boolean;
  isVisible: boolean;
  layerName: string;
  zIndex: number;
  isVhrView: boolean;
  colorMap: string;
  colorFormula: string;
}

export const SatelliteImageLayer = ({
  aoiSatelliteUrl,
  isGrayscaleSatelliteActive: isGrayscaleSatellite,
  isVisible,
  layerName,
  zIndex,
  isVhrView,
  colorMap,
  colorFormula,
}: SatelliteImageLayerProps) => {
  const [cogData, setCogData] = useState<{ cog: string | undefined; bounds: LeafletBounds | undefined }>({
    cog: undefined,
    bounds: undefined,
  });

  // TODO: remove when vhr cogs handling on backend will be ready
  const vhrCogUrl =
    '/tms/cog/tiles/WebMercatorQuad/{z}/{x}/{y}@1x?url=%2Fvsiaz%2Fusers%2Fdk%2FSKYWATCH_PL_PS_20170528T0849_ALL_Tile_0_0_ad19.tif&rescale=0%2C0.4&bidx=3&bidx=2&bidx=1';
  // TODO

  const grayscaleSatelliteCogUrl = titilerApiAdapter.buildGrayscaleSatelliteImageUrl(aoiSatelliteUrl, {
    rescaleFactor: '0,8192',
    fileType: 'png',
    colorMap,
  });

  const satelliteCogUrl = titilerApiAdapter.buildSatelliteImageUrl(aoiSatelliteUrl, {
    rescaleFactor: '0,8192',
    fileType: 'png',
    colorFormula,
  });

  const { extentLatLngBounds } = useGetExtentPolygon(getAzureBlobStorageUrl(aoiSatelliteUrl));

  useEffect(() => {
    switch (true) {
      case !isGrayscaleSatellite && isVhrView:
        setCogData({ cog: vhrCogUrl, bounds: undefined });
        break;
      case isGrayscaleSatellite && !!aoiSatelliteUrl:
        setCogData({ cog: grayscaleSatelliteCogUrl, bounds: extentLatLngBounds });
        break;
      default:
        setCogData({ cog: satelliteCogUrl, bounds: extentLatLngBounds });
    }
  }, [satelliteCogUrl, isGrayscaleSatellite, colorMap]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {cogData && (extentLatLngBounds || isVhrView) ? (
        <map.DataLayer
          isVisible={isVisible}
          layerName={layerName}
          zIndex={zIndex}
          bounds={extentLatLngBounds}
          cogUrl={cogData.cog}
          fixed={isVhrView}
        />
      ) : null}
    </>
  );
};
