import { useEffect, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useRefreshLayer = (dependencies: any[]) => {
  const [visible, setVisible] = useState(true);

  const dependencyList = JSON.stringify(dependencies);

  useEffect(() => {
    setVisible(false);

    setTimeout(() => setVisible(true));
  }, [dependencyList]);

  return { visible };
};
