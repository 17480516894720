import { useEffect } from 'react';
import styled from 'styled-components';

import { LabeledCheckbox } from 'ui';
import { type GeoJsonResultDto } from 'common/types/mapData';
import { useInitializeLayersOrderRecord } from '../draggableLayerManager/useInitializeLayersOrderRecord';
import { type LAYERS_KEYS } from '../draggableLayerManager/draggableLayersKeys';
import { LegendItemWraper, IndentedContentWraper } from '../styled';
import { useLayerManagerStore } from '../layerManagerStore';
import { DraggableLayerManagerSection } from '../draggableLayerManager';

interface InfrastructureItemProps {
  id: LAYERS_KEYS;
  shapes: GeoJsonResultDto[];
}

export const InfrastructureItem = ({ id, shapes }: InfrastructureItemProps) => {
  const geoJSONVisibility = useLayerManagerStore.use.geoJSONsVisibility();
  const toggleGeoJSONVisibility = useLayerManagerStore.use.toggleGeoJSONVisibility();
  const updateGeoJSONsVisibility = useLayerManagerStore.use.updateGeoJSONsVisibility();

  useEffect(() => {
    updateGeoJSONsVisibility(shapes.reduce((acc, { name }) => ({ ...acc, [name]: true }), {}));
  }, [shapes?.length]); // eslint-disable-line react-hooks/exhaustive-deps

  useInitializeLayersOrderRecord(id, Array.from(Array(shapes.length).keys()));

  return (
    <DraggableLayerManagerSection id={id}>
      {shapes.map(({ id, name, label_name }) => (
        <Wrapper key={id}>
          <IndentedContentWraper>
            <LabeledCheckbox
              children={label_name}
              variant="WIDE"
              onChange={() => toggleGeoJSONVisibility(name)}
              checked={geoJSONVisibility[name] || false}
            />
          </IndentedContentWraper>
        </Wrapper>
      ))}
    </DraggableLayerManagerSection>
  );
};

const Wrapper = styled(LegendItemWraper)`
  padding-left: 8px;
  border: none;
`;
