import { useEffect, useState } from 'react';

import { type ColormapDictionary } from 'common/types';
import { type LeafletBounds } from 'common/types/mapData';
import { titilerApiAdapter } from 'services/titiler/titilerApiAdapter';
import { useGetCogWithBoundsImproved } from 'services/titiler/hooks';
import { sanitizeMlStorageUrl } from 'services/titiler/utils';

export const useGetVegetationMapLayerDataUseCase = (url: string, colormap: ColormapDictionary, range: number[]) => {
  const [cogData, setCogData] = useState<{ url: string; bounds: LeafletBounds } | undefined>(undefined);

  const fileUrl = sanitizeMlStorageUrl(url);
  const { cogUrl, extentLatLngBounds } = useGetCogWithBoundsImproved(
    titilerApiAdapter.buildDiscreteColorScaleFloatCogUrl,
    fileUrl,
    {
      range,
      colormapObject: colormap,
      noDataValue: 0,
    },
  );

  useEffect(() => {
    if (cogUrl && extentLatLngBounds) {
      setCogData({ url: cogUrl, bounds: extentLatLngBounds });
    }
  }, [cogUrl, !!extentLatLngBounds]); // eslint-disable-line react-hooks/exhaustive-deps

  return { cogUrl: cogData?.url, cogBounds: cogData?.bounds, originalUrl: url };
};
