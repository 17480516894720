import { useState } from 'react';
import { useMap } from 'react-leaflet';

import { type TooltipData, type LeafletBounds, type LeafletLatLng } from 'common/types/mapData';
import { debounce } from 'common/utils';
import { TOOLTIP_DISPLAY_DELAY } from 'common/constants';
import { useAoiNumber, useQueryParameter } from 'common/navigation/hooks';
import { WATERBODY_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { checkIsOutsideBounds } from 'common/utils/map';
import { type ValueAndLabel } from 'common/types';
import { titilerApiService } from 'services/titiler/titilerApiService';
import { MapTooltip } from 'ui/map/tooltip';
import { useGetWaterAreaResultsUseCase } from 'views/water/surface/getWaterAreaResultsUseCase';
import { waterRepository } from 'infrastructure/water/waterRepository';
import { getAzureBlobStorageUrl } from 'services/titiler/utils';
import { buildWaterBodyTooltipStringHelper } from 'domain/water/helpers';

export const useGetInfoFromWaterAreaPoint = () => {
  const areaId = useAoiNumber();

  const { waterBodiesList } = waterRepository.useFetchWaterbodies(areaId);

  const { waterAreaResults } = useGetWaterAreaResultsUseCase();

  const getInfoFromWaterAreaPoint = async (url: string, lng: number, lat: number, resultId: number) => {
    const info = await titilerApiService.getPointInfoGeneric(getAzureBlobStorageUrl(url), lng, lat, 1);

    const waterbodyId =
      info?.values && info.values[0] && waterAreaResults.find(({ id }) => id === Number(resultId))?.waterbody_id;
    const waterbodyName = waterBodiesList && waterBodiesList.find(({ id }) => id === waterbodyId)?.name;

    return { info, waterbodyId, waterbodyName };
  };

  return { getInfoFromWaterAreaPoint };
};

interface WaterAreaTooltipProps {
  isDataLayerVisible: boolean;
  urlsWithIds: { url: string; id: number; bounds: LeafletBounds }[];
  onWaterAreaClick: (waterBody: ValueAndLabel, latLng: LeafletLatLng) => void;
}

export const WaterAreaTitilerTooltip = ({
  isDataLayerVisible,
  urlsWithIds,
  onWaterAreaClick,
}: WaterAreaTooltipProps) => {
  const _waterbodyIdQuery = useQueryParameter(WATERBODY_QUERY_PARAMETER);
  const { getInfoFromWaterAreaPoint } = useGetInfoFromWaterAreaPoint();

  const [tooltip, setTooltip] = useState<TooltipData | undefined>();

  const map = useMap();

  const buildWaterAreaTooltip = buildWaterBodyTooltipStringHelper();

  map.off('click');
  map.off('mousemove');

  map.on(
    'mousemove',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    debounce(async (event: any) => {
      const latLng = await map.mouseEventToLatLng(event.originalEvent);

      urlsWithIds.forEach(async (set) => {
        if (!set?.id || !set?.url || !set.id || !set.url || checkIsOutsideBounds(latLng, set.bounds)) {
          return;
        }

        const { info, waterbodyName } = await getInfoFromWaterAreaPoint(set.url, latLng?.lng, latLng?.lat, set.id);

        if (info.detail === 'Point is outside dataset bounds') {
          setTooltip(undefined);
        }

        if (info?.values && latLng?.lat && latLng?.lng && info.values[0] > 0 && isDataLayerVisible) {
          setTooltip({
            id: set.id,
            lat: latLng.lat,
            lng: latLng.lng,
            value: info.values[0],
            label: waterbodyName,
          });
        }
      });
    }, TOOLTIP_DISPLAY_DELAY),
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  map.on('click', async (event: any) => {
    const latLng = await map.mouseEventToLatLng(event.originalEvent);

    urlsWithIds.forEach(async (set) => {
      if (!set?.id || !set?.url) {
        return;
      }

      const { waterbodyId, waterbodyName } = await getInfoFromWaterAreaPoint(set.url, latLng?.lng, latLng?.lat, set.id);

      return onWaterAreaClick({ value: String(waterbodyId), label: waterbodyName || '' }, latLng);
    });
  });

  return tooltip ? <MapTooltip tooltipData={tooltip} content={buildWaterAreaTooltip(tooltip)} /> : null;
};
