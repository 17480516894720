import chroma from 'chroma-js';

import { buildCoordinatesOnlyTooltipStringHelper } from 'common/utils/map';
import { useLayerManagerStore } from 'components/layerManager/layerManagerStore';
import { DataLayer } from 'components/map';
import { MapClick } from 'components/map/tooltip/MapClick';
import { titilerApiAdapter } from 'services/titiler/titilerApiAdapter';
import { useGetExtentPolygon } from 'views/areas/areaDetails/useGetExtentPolygon';
import { Loading } from 'ui/molecules/loading';
import { SNOW_COVER_COLOR } from 'domain/water/colors';

interface SnowCoverMapLayerProps {
  sourceUrl: string;
}

export const SnowCoverMapLayer = ({ sourceUrl }: SnowCoverMapLayerProps) => {
  const isVisible = useLayerManagerStore.use.isDataLayerVisible();

  const colormapArray = [
    [
      [0.0001, 1.0001],
      [...chroma(SNOW_COVER_COLOR).rgb(), 255],
    ],
  ];

  const titilerUrl = titilerApiAdapter.buildGenericMonocolorCogUrl(`${sourceUrl}`, {
    expression: 'b1',
    colormap: JSON.stringify(colormapArray),
  });

  const { extentLatLngBounds, isInfoLoading } = useGetExtentPolygon(sourceUrl);

  if (isInfoLoading) {
    return <Loading />;
  }

  if (!titilerUrl || !extentLatLngBounds) {
    return null;
  }

  return (
    <>
      <DataLayer
        isVisible={isVisible}
        layerName={'snowCoverLayer'}
        zIndex={480}
        cogUrl={titilerUrl}
        bounds={extentLatLngBounds}
        opacity={1}
      />
      {isVisible && <MapClick tooltipStringBuilder={buildCoordinatesOnlyTooltipStringHelper} />}
    </>
  );
};
