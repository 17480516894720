import { type ComponentType } from 'react';

import { Loading } from 'ui/molecules/loading';
import { titilerInfoService } from 'services/titiler/titilerInfoService';
import { getAzureBlobStorageUrl } from 'services/titiler/utils';
import { type MoistureIndicator, type MoistureCommonProps } from 'domain/water/types';

interface Props<T> {
  Component: ComponentType<T & MoistureCommonProps>;
  props?: T;
  url: string;
  layerName: string;
  indicator: MoistureIndicator;
  enableLoading?: boolean;
}

export const WithMoistureCogStats = <T,>({ Component, props, url, layerName, indicator, enableLoading }: Props<T>) => {
  const { stats, isStatsLoading } = titilerInfoService.useGetStatsGeneric(getAzureBlobStorageUrl(url));
  const cogMin = stats?.b1?.min;
  const cogMax = stats?.b1?.max;

  if (enableLoading && isStatsLoading) {
    return <Loading />;
  }

  if (typeof cogMin !== 'number' && typeof cogMax !== 'number') {
    return null;
  }

  const prec = indicator.precision;
  const min = Math.ceil(cogMin * 10 ** prec) / 10 ** prec;
  const max = Math.floor(cogMax * 10 ** prec) / 10 ** prec;

  return (
    <Component {...(props as T)} indicator={indicator} layerName={layerName} min={min} max={max} sourceUrl={url} />
  );
};
