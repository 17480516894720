import { useAoiNumber } from 'common/navigation/hooks';
import { useGetAoiName } from 'common/hooks/getAoiName';
import { buildCoordinatesOnlyTooltipStringHelper } from 'common/utils/map';
import { MapClick } from 'components/map/tooltip/MapClick';
import { useShareModalStore } from 'ui/organisms/shareModal/shareModalStore';
import { useExplorationLayerManagerStore } from './explorationLayerManagerStore';
import { SamMineralsMapContent, MtmfMineralsMapContent, MlMineralsMapContent } from './minerals';
import { LineamentDensityMapContent, LineamentsCollection, useLineaments } from './lineaments';
import { IndicesMapContent, useIndices } from './indices';
import { ClustersMapContent } from './clusters';
import { CompositesMapContent } from './composites';
import { ExplorationDownloadModal } from './ExplorationDownloadModal';
import { useSurfaceMinerals } from './useSurfaceMinerals';
import { useExplorationLayers } from './useExplorationLayers';
import { usePrepareSortedLayersList } from './usePrepareSortedLayersList';
import { useClustersLayerManagerStore } from './clusters/clustersLayerManagerStore';
import { useLineamentsLayerManagerStore } from './lineaments/lineamentsLayerManagerStore';

export const ExplorationMapContent = () => {
  const areaId = useAoiNumber();
  const areaName = useGetAoiName();

  const { indicesResults } = useIndices();
  const { selectedMinerals: samMinerals } = useSurfaceMinerals(1);
  const { selectedMinerals: mtmfMinerals } = useSurfaceMinerals(2);
  const { selectedMinerals: mlMinerals } = useSurfaceMinerals(3);

  const { visibleExplorationLayers } = useExplorationLayers();

  const { selectedOption, rangeResult } = useLineaments();
  const isClustersSelectionMode = useClustersLayerManagerStore.use.isClustersSelectionMode();
  const isLineamentsSelectionMode = useLineamentsLayerManagerStore.use.isLineamentsSelectionMode();
  const setExplorationLayersPointInfo = useExplorationLayerManagerStore.use.setExplorationLayersPointInfo();
  const isModalOpen = useShareModalStore.use.isShareModalOpen();

  usePrepareSortedLayersList();

  return (
    <>
      {!isClustersSelectionMode && areaName && selectedOption && rangeResult && (
        <LineamentsCollection {...{ areaName, selectedOption, rangeResult }} />
      )}
      {!isLineamentsSelectionMode && <ClustersMapContent />}
      {!isClustersSelectionMode && !isLineamentsSelectionMode && (
        <>
          {areaName && <LineamentDensityMapContent areaName={areaName} />}
          {!!indicesResults.length && <IndicesMapContent areaId={areaId} indicesResults={indicesResults} />}
          {!!samMinerals.length && <SamMineralsMapContent areaId={areaId} minerals={samMinerals} />}
          {!!mtmfMinerals.length && <MtmfMineralsMapContent areaId={areaId} minerals={mtmfMinerals} />}
          {!!mlMinerals.length && <MlMineralsMapContent minerals={mlMinerals} />}
          <CompositesMapContent />
          <MapClick
            layersWithUrls={visibleExplorationLayers}
            setLayersPointInfo={setExplorationLayersPointInfo}
            tooltipStringBuilder={buildCoordinatesOnlyTooltipStringHelper}
          />
        </>
      )}
      {isModalOpen && <ExplorationDownloadModal />}
    </>
  );
};
