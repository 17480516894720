import { useQueries } from '@tanstack/react-query';

import { greeneryApi } from 'infrastructure/greenery/greeneryApiAdapter';
import { getAzureBlobStorageUrl } from 'services/titiler/utils';
import { useGetExtentPolygon } from 'views/areas/areaDetails/useGetExtentPolygon';
import { type GreeneryResult } from 'domain/greenery/types';

export const useGetGreeneryCogUrlsWithIdsUseCase = (greeneryResultsData: GreeneryResult[] = []) => {
  const resultIds = greeneryResultsData?.map((object) => object.id);

  const urlsResult = useQueries({
    queries: resultIds.map((id) => ({
      queryKey: ['vegetation_cog', id],
      queryFn: () => greeneryApi.getVegetationCog(id),
    })),
  });

  const urls = urlsResult
    .map((r) => r.data)
    .filter((data) => !!data)
    .map((data) => {
      const id = greeneryResultsData.find(
        (result) => result.id === Number(data?.config.url?.replace(/[^0-9]/g, '')),
      )!.greenery_object_id;

      const url = data?.data.url;

      return {
        url: url ? getAzureBlobStorageUrl(url) : '',
        id,
      };
    })
    .filter((data) => !!data.url.length);

  const { extentLatLngBounds } = useGetExtentPolygon(urls[0]?.url);

  const urlsWithBounds = urls.map((url) => ({
    ...url,
    bounds: extentLatLngBounds,
  }));

  return { urlsWithBounds };
};
