import { useLayerManagerStore } from 'components';
import { WithCogBandRange } from 'services/titiler/WithCogBandRange';
import { LINEAMENT_DENSITY_SLIDER_CONFIG } from 'domain/exploration/configs';
import { LINEAMENT_DENSITY_LAYER_NAME } from 'domain/exploration/constants';
import { useLineamentDensity } from './useLineamentDensity';
import { LineamentDensityMapLayer } from './LineamentDensityMapLayer';
import { useLineamentsLayerManagerStore } from './lineamentsLayerManagerStore';

interface LineamentDensityMapContentProps {
  areaName: string;
}

export const LineamentDensityMapContent = ({ areaName }: LineamentDensityMapContentProps) => {
  const { densityResult } = useLineamentDensity(areaName);
  const layersTresholdsValues = useLayerManagerStore.use.layersTresholdsValues();
  const areLineamentsVisible = useLineamentsLayerManagerStore.use.areLineamentsVisible();
  const isLineamentDensityVisible = useLineamentsLayerManagerStore.use.isLineamentDensityVisible();
  const threshold = layersTresholdsValues[LINEAMENT_DENSITY_LAYER_NAME];

  if (!densityResult || typeof threshold !== 'number') {
    return null;
  }

  const { url } = densityResult;

  return (
    <WithCogBandRange
      Component={LineamentDensityMapLayer}
      props={{
        name: LINEAMENT_DENSITY_LAYER_NAME,
        url,
        isVisible: isLineamentDensityVisible && areLineamentsVisible,
        threshold,
      }}
      url={url}
      isFetchingEnabled={isLineamentDensityVisible && areLineamentsVisible}
      precision={LINEAMENT_DENSITY_SLIDER_CONFIG.precision}
    />
  );
};
