import { titilerApiAdapter } from 'services/titiler/titilerApiAdapter';
import { type UrlWithProps, type UrlWithNameAndLabel } from 'services/titiler/types';
import { getAzureBlobStorageUrl } from 'services/titiler/utils';
import { isPointInfoArrayValid } from './typeguards';

export const titilerApiService = {
  getInfoGeneric: async (url: string) => {
    const geotiffInfoUrl = titilerApiAdapter.buildGenericGeorasterInfoUrl(url);
    const response = await fetch(geotiffInfoUrl);
    const geotiffInfo = await response.json();

    return await geotiffInfo;
  },

  getRichInfo: async (url: string, id: number) => {
    const geotiffInfoUrl = titilerApiAdapter.buildGenericGeorasterInfoUrl(url);
    const response = await fetch(geotiffInfoUrl);
    const geotiffInfo = await response.json();
    const info = await geotiffInfo;

    return { geotiffInfo: info, id: id };
  },

  getExternalInfo: async (url: string) => {
    const geotiffInfoUrl = titilerApiAdapter.buildExternalGeorasterInfoUrl(url);
    const response = await fetch(geotiffInfoUrl);
    const geotiffInfo = await response.json();

    return await geotiffInfo;
  },

  getStatsGeneric: async (url: string, range?: number[], bins?: number) => {
    const geotiffStatsUrl = titilerApiAdapter.buildGenericGeorasterStatisticsUrl(url, range, bins);
    const response = await fetch(geotiffStatsUrl);
    const geotiffStats = response.json();

    return await geotiffStats;
  },

  getCogBandStats: async (url: string, band: number) => {
    const geotiffStatsUrl = titilerApiAdapter.buildGeorasterBandStatisticsUrl(url, band);
    const response = await fetch(geotiffStatsUrl);
    const geotiffStats = response.json();

    return await geotiffStats;
  },

  getStatsWithPropsGeneric: async <T>(url: string, props: T) => {
    const geotiffStatsUrl = titilerApiAdapter.buildGenericGeorasterStatisticsUrl(url);
    const response = await fetch(geotiffStatsUrl);
    const geotiffStats = response.json();
    const stats = await geotiffStats;

    return { stats: stats, props };
  },

  getRichStats: async (url: string, name: string) => {
    const geotiffStatsUrl = titilerApiAdapter.buildGenericGeorasterStatisticsUrl(url);
    const response = await fetch(geotiffStatsUrl);
    const geotiffStats = response.json();
    const stats = await geotiffStats;

    return { stats: stats, name };
  },

  getPointInfoGeneric: async (url: string, lat: number, lng: number, layer?: number) => {
    const pointInfoUrl = titilerApiAdapter.buildGenericGeorasterPointInfoUrl(url, lat, lng, layer);
    const response = await fetch(pointInfoUrl);
    const pointInfo = response.json();

    return await pointInfo;
  },

  getPointsArrayInfo: async (urlsWithProps: UrlWithProps[], lat: number, lng: number, layer?: number) => {
    const requests = urlsWithProps.map(async ({ url, ...rest }) => ({
      response: await fetch(
        titilerApiAdapter.buildGenericGeorasterPointInfoUrl(getAzureBlobStorageUrl(url), lat, lng, layer),
      ),
      ...rest,
    }));
    const responses = await Promise.all(requests);
    const resolvedResponses = responses.filter(({ response }) => response.ok);
    const json = resolvedResponses.map(async ({ response, ...rest }) => ({ info: await response.json(), ...rest }));
    const data = Promise.all(json);

    return await data;
  },

  getPointInfoArray: async <T>(urlsWithProps: (T & { url: string })[], lng: number, lat: number, band = 1) => {
    const requests = urlsWithProps.map(async (item) => ({
      response: await fetch(titilerApiAdapter.buildGenericSwappedCogPointUrl(`/vsiaz${item.url}`, lng, lat, band)),
      ...item,
    }));
    const responses = await Promise.all(requests);
    const resolvedResponses = responses.filter(({ response }) => response.ok);
    const json = resolvedResponses.map(async ({ response, ...rest }) => ({ info: await response.json(), ...rest }));
    const data = await Promise.all(json);

    const pointInfoArray = data && isPointInfoArrayValid(data) ? data : [];

    return pointInfoArray;
  },

  getPointsArrayInfoWithLabel: async (
    urlsWithNameAndLabel: UrlWithNameAndLabel[],
    lat: number,
    lng: number,
    layer?: number,
  ) => {
    const requests = urlsWithNameAndLabel.map(async ({ url, ...rest }) => ({
      response: await fetch(
        titilerApiAdapter.buildGenericGeorasterPointInfoUrl(getAzureBlobStorageUrl(url), lat, lng, layer),
      ),
      ...rest,
    }));
    const responses = await Promise.all(requests);
    const resolvedResponses = responses.filter(({ response }) => response.ok);
    const json = resolvedResponses.map(async ({ response, ...rest }) => ({ info: await response.json(), ...rest }));
    const data = Promise.all(json);

    return await data;
  },
};
