import { useLocation } from 'react-router';

import { useMapStore } from 'components/map/mapStore';
import { ExtractionMapContent } from 'views/mine/ExtractionMapContent';
import { WaterQualityMapContent } from 'views/water/quality/WaterQualityMapContent';
import { WaterAreaMapContent } from 'views/water/surface/WaterAreaMapContent';
import { VegetationMapContent } from 'views/greenery/vegetation/VegetationMapContent';
import { SegmentationMapContent } from 'views/greenery/segmentation/SegmentationMapContent';
import { QuarterlyMapContent } from 'views/greenery/quarterly/QuarterlyMapContent';
import { VHRMapContent } from 'views/greenery/vhr/VHRMapContent';
import { VCIMapContent } from 'views/greenery/vci/VCIMapContent';
import { MonitoringMapContent } from 'views/monitoring/map/MonitoringMapContent';
import { ExplorationMapContent } from 'views/exploration/ExplorationMapContent';
import { MoistureMapContent } from 'views/water/moisture/MoistureMapContent';
import { TemperatureMapContent } from 'views/temperature/TemperatureMapContent';
import { SubsidenceMapContent } from 'views/displacements/mapContent/SubsidenceMapContent';
import { SnowCoverMapContent } from 'views/water/snow/SnowCoverMapContent';
import { DisplacementsActiveTab } from 'domain/displacements/enums';
import { GreeneryActiveTab } from 'domain/greenery/enums';
import { WaterActiveTab } from 'domain/water/enums';
import { MONITORING_PATH_NAME } from 'domain/monitoring/constants';
import { MineralizationActiveTab } from 'domain/exploration/enums';
import { ExtractionActiveTab } from 'domain/extraction/enums';
import { TEMPERATURE_PATH_NAME } from 'domain/temperature/constants';

export const MapContent = () => {
  const mapReady = useMapStore.use.mapReady();

  const { pathname } = useLocation();

  switch (true) {
    case !mapReady:
      return null;

    case pathname.endsWith(MONITORING_PATH_NAME):
      return <MonitoringMapContent />;

    case pathname.includes(MineralizationActiveTab.SURFACE):
      return <ExplorationMapContent />;

    case pathname.includes(ExtractionActiveTab.TERRAIN):
      return <ExtractionMapContent />;

    case pathname.includes(WaterActiveTab.QUALITY):
      return <WaterQualityMapContent />;

    case pathname.includes(WaterActiveTab.SURFACE):
      return <WaterAreaMapContent />;

    case pathname.includes(WaterActiveTab.MOISTURE):
      return <MoistureMapContent />;

    case pathname.includes(WaterActiveTab.SNOW_COVER):
      return <SnowCoverMapContent />;

    case pathname.includes(GreeneryActiveTab.SEGMENTATION):
      return <SegmentationMapContent />;

    case pathname.includes(GreeneryActiveTab.QUARTERLY):
      return <QuarterlyMapContent />;

    case pathname.includes(GreeneryActiveTab.VEGETATION):
      return <VegetationMapContent />;

    case pathname.includes(GreeneryActiveTab.VHR):
      return <VHRMapContent />;

    case pathname.includes(GreeneryActiveTab.VCI):
      return <VCIMapContent />;

    case pathname.includes(DisplacementsActiveTab.SUBSIDENCE):
      return <SubsidenceMapContent />;

    case pathname.includes(TEMPERATURE_PATH_NAME):
      return <TemperatureMapContent />;

    default:
      return null;
  }
};
