import { type LayerObject } from 'common/types/mapData';

export const layerManagerOptionsBuildHelper = (objectsConfiguration: LayerObject[], onlyVisibleIds?: number[]) => {
  const options = objectsConfiguration.map(({ id, name }) => {
    const visibility = onlyVisibleIds ? !!onlyVisibleIds?.some((visibleId) => visibleId === id) : true;
    return [name, visibility];
  });

  return Object.fromEntries(options);
};

export const areThresholdsEqual = (firstThreshold: number | number[], secondThreshold: number | number[]): boolean => {
  if (typeof firstThreshold === 'number' && typeof secondThreshold === 'number' && firstThreshold === secondThreshold) {
    return true;
  }

  if (
    Array.isArray(firstThreshold) &&
    firstThreshold.length === 2 &&
    Array.isArray(secondThreshold) &&
    secondThreshold.length === 2 &&
    firstThreshold[0] === secondThreshold[0] &&
    firstThreshold[1] === secondThreshold[1]
  ) {
    return true;
  }

  return false;
};
